import type {NextPage} from 'next'
import React from "react";
import Head from "next/head";
import Layout from '../components/layouts/Layout';
import Container from "../components/layouts/Container";
import Image from "next/image";
import Link from '../components/link/Link';
import i18next from "i18next";

const Error404Page: NextPage = (context: any) => {
    return (
        <>
            <Layout context={context}>
                <Container>
                <div className={"row"}>
                        <div className={"col-12 col-md-6"}>
                            <Image src={i18next.t('404page.imageUrl').toString()}
                                   height={600} width={600} alt={"Error Image"} className={"w-100 h-auto"}/>
                        </div>
                        <div className={"col-12 col-md-6"}>
                            <h1 className="fs-3">{i18next.t('404page.title').toString()}</h1>
                            <hr className={"mb-4"}/>
                            <p>{i18next.t('404page.subtitle1').toString()}</p>
                            <p className={"mb-4"}>{i18next.t('404page.subtitle2').toString()}</p>
                            <Link href={'tel:+41525441600'} className={"d-block mb-3"}>
                                <i className="fa-solid fa-phone"></i>
                                <span className={"ms-2 text-decoration-underline"}>
                                    {i18next.t('404page.linkSupportText').toString()}
                                </span>
                            </Link>
                            <Link href={i18next.t('404page.linkNovelty').toString()} className={"d-block mb-3"}>
                                <i className="fa-solid fa-angle-right"></i>
                                <span className={"ms-2 text-decoration-underline"}>
                                    {i18next.t('404page.linkNoveltyText').toString()}
                                </span>
                            </Link>
                            <Link href={i18next.t('404page.linkSale').toString()} className={"d-block mb-3"}>
                                <i className="fa-solid fa-angle-right"></i>
                                <span className={"ms-2 text-decoration-underline"}>
                                    {i18next.t('404page.linkSaleText').toString()}
                                </span>
                            </Link>
                        </div>
                    </div>
                </Container>
            </Layout>
        </>
    )
}

export default Error404Page
